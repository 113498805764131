<div class="mainContainer vw-100 d-flex" [style.height.px]="screenHeight">
    <div class="sideBarDiv">
        <app-side-bar [userData]="userData"></app-side-bar>
    </div>
    <div class="bodyContainer">
        <router-outlet></router-outlet>
    </div>
</div>

<p-dialog header="Sign Up" class="signUpModal" [modal]="true" [(visible)]="showCompleteProfileModal" [draggable]="false"
    [closable]="false">
    <div class="mt-4">
        <span class="p-text-secondary">We are really happy to have you on board. Please fill in the following details to
            complete the sign up process.</span>
    </div>
    <form [formGroup]="staffRegistrationForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="firstName">First Name</label>
                <input pInputText id="firstName" formControlName="firstName" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="lastName">Last Name</label>
                <input pInputText id="lastName" formControlName="lastName" />
            </div>
            <div class="d-flex flex-column gap-2 mb-2">
                <label for="mobileNumber">Mobile Number</label>
                <input pInputText id="mobileNumber" formControlName="mobileNumber" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Save" [disabled]="staffRegistrationForm.invalid" (onClick)="updateStaffDetails()" />
    </div>
</p-dialog>