import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor() { }

  showNotification(title: string, body:
    string, icon?: string, additionalOptions?: NotificationOptions) {
    if (Notification.permission === 'granted') {
      const options: NotificationOptions = {
        body,
        icon,
        ...additionalOptions
      };
      new Notification(title, options);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          this.showNotification(title,
            body, icon);
        }
      });
    }
  }
}
