import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrl: './title-bar.component.scss'
})
export class TitleBarComponent {

  @Input() activeNav: string = '';
  activeAction: string | null = null;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
  }

}
