import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrl: './outlets.component.scss'
})
export class OutletsComponent {
  activeAction: string | null = null;
  outlets: any[] =[];
  expandedRows: any[] = [];
  selectedDate: Date | null = null;
  locations: any[] = [];
  selectedLocation: any = null;
  value: string = '';


  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
    this.outlets = [
      {
        restaurantName: 'Frutify',
        restaurantmail: 'contactfruitify@gmail.com',
        restaurantId: '234567',
        MobileNo: '8919619792',
        Location: 'Eluru',
        OnlineHours: '8 hours',
        JoinedDate: '30 Jan 2024',
        LastLoginDate: '7 Sep 2024',
        lastLoginTime: '7:13 PM'
      },
      
    ];
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }
}
