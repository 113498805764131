import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  locationsCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) { 
    this.locationsCollection = fireStore.collection('locations');
  }

  async addNewLocation(obj: any) {
    const locationIdQuery = this.fireStore.collection('locations').ref.where('locationId', '==', obj.locationId);
    const locationNameQuery = this.fireStore.collection('locations').ref.where('locationName', '==', obj.locationName);
  
    const [locationIdSnapshot, locationNameSnapshot] = await Promise.all([locationIdQuery.get(), locationNameQuery.get()]);
  
    if (!locationIdSnapshot.empty && !locationNameSnapshot.empty) {
      return 3;
    } else if (!locationNameSnapshot.empty) {
      return 2;
    } else if (!locationIdSnapshot.empty) {
      return 1;
    }
  
    return this.locationsCollection.doc().set(obj);
  }

  async updateLocation(docId: string, updateObj: any) {
    const existingLocationNameQuery = this.fireStore.collection('locations').ref
      .where('locationName', '==', updateObj.locationName)
      .where(firebase.firestore.FieldPath.documentId(), '!=', docId); // Exclude the current document
  
    const querySnapshot = await existingLocationNameQuery.get();
  
    if (!querySnapshot.empty) {
      return 2;
    }
  
    await this.locationsCollection.doc(docId).update(updateObj);
    return 4;
  }

  async addNewLocality(docId: string, newArray: any[]) {
    const docRef = this.locationsCollection.doc(docId);
  
    try {
      await docRef.update({
        localities: firebase.firestore.FieldValue.arrayUnion(...newArray) 
      });
      console.log('Array added successfully!');
    } catch (error) {
      console.error('Error adding array:', error);
      // Handle the error appropriately (e.g., show a user-friendly message)
    }
  }

  async updateLocality(locationDocId: string, localityId: string, updateObj: any) {
    const docRef = this.locationsCollection.doc(locationDocId);

    try {
        docRef.get().subscribe(async docSnapshot => { // Subscribe to the observable
            if (docSnapshot.exists) {
                const currentLocalities = docSnapshot.data()?.['localities'] || []; 

                const localityIndex = currentLocalities.findIndex((locality: any) => locality.localityId === localityId);

                if (localityIndex > -1) {
                    // Update the locality at the found index
                    currentLocalities[localityIndex] = { ...currentLocalities[localityIndex], ...updateObj };

                    // Update the Firestore document with the modified localities array
                    try {
                      await docRef.update({ localities: currentLocalities });
                      console.log('Locality updated successfully!');
                      return 4; // Indicate successful update
                    } catch (error) {
                      console.error('Error updating locality:', error);
                      return error;
                    }
                } else {
                    console.error('Locality not found with ID:', localityId);
                    return 1;
                    // Handle the case where the locality is not found within the location
                }
            } else {
                console.error("Location not found with ID:", locationDocId);
                return 1;
                // Handle the case where the location is not found
            }
        }, (error) => {
            console.error('Error fetching document:', error);
            // Handle the error appropriately 
        });
    } catch (error) {
        console.error('Error updating locality:', error);
        // Handle the error appropriately (e.g., show a user-friendly message)
    }
}

  getLocationData() {
    return this.locationsCollection.get().pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => {
          const data = doc.data();
          return { id: doc.id, ...data }; // Include the document ID in the returned object
        });
      })
    );
  }

  deleteLocation(docId: string) {
    return this.locationsCollection.doc(docId).delete();
  }

  deleteLocality(docId: string, updatedData: any) {
    return this.locationsCollection.doc(docId).update(updatedData);
  }

  getActiveLocation(locationId: string | undefined) {
    return this.locationsCollection.doc(locationId).get();
  }

  async updateLocationStatus(locationDocId: string, updateData: any) {
      const docRef = this.locationsCollection.doc(locationDocId);
    
      try {
        await docRef.update(updateData);
        console.log('Location status updated successfully!');
        return 4; // Indicate successful update
      } catch (error) {
        console.error('Error updating location status:', error);
        // Handle the error appropriately (e.g., show a user-friendly message)
        throw error; // Or return a Promise.reject() if you prefer
      }
    }
}
