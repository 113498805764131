import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  staffCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient, private fns: AngularFireFunctions) { 
    this.staffCollection = fireStore.collection('staff');
  }

  getAllStaffMembers() {
    return this.staffCollection.ref
      .where(firebase.firestore.FieldPath.documentId(), '!=', 'applications') // Exclude the 'applications' document
      .get();
  }

  sendEmail(to: string[], subject: string, templatePath: string): Observable<any> { // Pass template path
    // Read the HTML template file
    return this.http.get(templatePath, { responseType: 'text' })
      .pipe(
        switchMap((htmlContent) => { // Use switchMap to chain requests
          const body = {
            to,
            subject,
            html: htmlContent // Include HTML content in the body
          };

          const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
          };

          return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/sendEmail', body, httpOptions);
        })
      );
  }

  createNewStaffMember(email: string, password: string) {
    const headers = { 'Content-Type': 'application/json' };
    const body = { email, password };

    return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/createNewStaffMember', body, { headers }).toPromise();
  }

  addStaff(uid: string, obj: any) {
    return this.staffCollection.doc(uid).set(obj)
  }

  completeStaffRegistration(uid: string, obj: any) {
    const data = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobile: obj.mobile,
    }
    return this.staffCollection.doc(uid).set(data, {merge: true});
  }
}
