<div class="mainContainer h-100 w-100">
    <div class="titleSection d-flex justify-content-between align-items-center">
        <span>
            {{activeNav}} <span *ngIf="activeAction">| {{activeAction}}</span>
        </span>
        <div class="notificationIconHolder">
            <i class="pi pi-bell text-3xl" pBadge value="2"></i>
            <!-- <span class="pi pi-bell"></span> -->
        </div>
        <!-- <span>
            <p-button label="Add Staff" icon="pi pi-plus" (onClick)="toggleAddStaffModal()" />
        </span> -->
    </div>
</div>