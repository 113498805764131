import { Component } from '@angular/core';
import { LocalNotificationService } from '../../shared/local-notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  constructor(private localNotification: LocalNotificationService) {}

  ngOnInit() {
    // setTimeout(() => {
    //   this.localNotification.showNotification('New Message', 'You have a new message!');
    //   console.warn('working');
    // }, 3000);
  }

}
