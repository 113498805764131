import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  staffCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) { 
    this.staffCollection = fireStore.collection('staff');
  }

  getCurrentStaff(uid: string) {
    return this.staffCollection.doc(uid).get();
  }
}
