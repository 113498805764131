import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss'
})
export class LogsComponent {
  activeAction: string | null = null;
  selectedDate: Date | null = null;
  value: string = '';
  logs: any[] =[];
  logTriggers: any[] =[];
  tabMenuItems: any[] = [];
  activeItem: MenuItem | undefined;
 
  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService) {
    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Log History'},
      { label: 'Log Triggers'},
    ];
    this.activeItem = this.tabMenuItems[0]
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
    this.logs = [
      {
        logId: '098765',
        featureName: 'Order',
        logType: 'Cancel',
        timeStamp: 1727296374007,
        summary: 'Order ID 78914 canceled by staff member Emily Roberts due to customer request.',
      },
      {
        logId: '078765',
        featureName: 'Outlet',
        logType: 'Update',
        timeStamp: 1727296374007,
        summary: 'Status of outlet Spicy Corner updated to Active by  Sarah .',
      },
    ];
  }

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

}
