<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Outlets'"></app-title-bar>
    </div>
    <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search"  />
            </p-iconField> 
        </div>
        <div class="d-flex gap-2">
            <div class="p-field ">
                <p-dropdown id="location" [options]="locations" [(ngModel)]="selectedLocation" placeholder="Location"></p-dropdown>
            </div>  
            <div class="p-date d-flex">
                <p-calendar 
                [(ngModel)]="selectedDate" 
                [iconDisplay]="'input'" 
                [showIcon]="true" 
                inputId="icondisplay" />
            </div>
        </div>
    </div>
    <div class="cardSection">
        Total Outlets<br>
        <span class="d-flex justify-content-center align-item-center">1</span>
    </div>
    <div class="bodySection">
        <p-table [value]="outlets" dataKey="ownerId" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>  
                    <th>Restaurant Id</th>
                    <th>Restaurant Info</th>
                    <th>Mobile No</th>
                    <th>Location</th>
                    <th>Active Hours</th>
                    <th>Onboarded Date</th>
                    <th>Last Active Session Date</th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-outlets let-expanded="expanded">
                <tr>
                    <td>{{outlets.restaurantId}}</td>
                    <td>
                        <div class="restaurantInfo d-flex gap-1">
                            <div class="imageContainer d-block">
                            <img src="../../../assets/fruitify.png" height="30px" width="30px">
                            </div>
                            <div class="infoField d-flex flex-column">
                                <span class="nameSection">{{outlets.restaurantName}}</span>
                                <span class="emailSection">{{outlets.restaurantmail}}</span>
                            </div>
                        </div>
                    </td>
                    <td>{{outlets.MobileNo}}</td>
                    <td>{{outlets.Location}}</td>
                    <td>{{outlets.OnlineHours}}</td>
                    <td>{{outlets.JoinedDate}}</td>
                    <td>
                        <div class="timeField d-flex flex-column">
                            <span class="dateSection">{{outlets.LastLoginDate}}</span>
                            <span class="timeSection">{{outlets.lastLoginTime}}</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-pen-to-square actionIconBtn" ></span>
                            <span class="pi pi-send actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>