<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Staff Members'"></app-title-bar>
    </div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Staff" icon="pi pi-plus" (onClick)="toggleAddStaffModal()" />
            </span>
        </div>
        <p-table [value]="staffMembers">
            <ng-template pTemplate="header">
                <tr>
                    <th>Staff Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Role</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-staff>
                <tr>
                    <td>{{ staff.staffId }}</td>
                    <td>{{ staff.firstName }} {{ staff.lastName }}</td>
                    <td>{{ staff.email }}</td>
                    <td>{{ staff.mobile }}</td>
                    <td>{{ staff.role }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td> 
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Add Staff" class="signUpModal" [modal]="true" [(visible)]="showStaffModal" [draggable]="false"
    [closable]="true">
    <div class="mt-2">
        <span class="p-text-secondary d-flex align-items-center gap-2"><span class="pi pi-info-circle"></span> Please
            ensure role that you are assigning to the specific user</span>
    </div>
    <form [formGroup]="addStaffForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="email">Email</label>
                <input pInputText id="email" formControlName="email" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="role">Role</label>
                <p-dropdown [options]="staffRoles" formControlName="role" optionLabel="name" appendTo="body"
                    placeholder="Select a Role" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Continue" [disabled]="addStaffForm.invalid" (onClick)="registerNewStaff()" />
    </div>
</p-dialog>