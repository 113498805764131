import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  staffCollection: AngularFirestoreCollection;
  ownersCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) { 
    this.staffCollection = fireStore.collection('staff');
    this.ownersCollection = fireStore.collection('restaurantOwners');
  }

  getOpenApplicationOwners() {
    return this.staffCollection.doc('applications').get();
  }

  async getOpenApplicationDetails(docId: any) {
    try {
      const docSnapshot = await this.ownersCollection.doc(docId).ref.get();
      if (docSnapshot.exists) {
        const data: any = docSnapshot.data();
        const { firstName, lastName, ownerId, email, mobile, openOutletApplications } = data; 
        return { firstName, lastName, ownerId, email, mobile, openOutletApplications }; 
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      return error
      // Handle the error appropriately
    }
  }
}
