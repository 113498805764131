<div class="mainContainer h-100 w-100">
  <div>
    <app-title-bar [activeNav]="'Logs'"></app-title-bar>
  </div>
  <div class="bodySection">
    <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
      (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
    <div class="cardHolder" *ngIf="activeItem!.label == 'Log History'">
      <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
          <p-iconField iconPosition="left">
            <p-inputIcon styleClass="pi pi-search" />
            <input type="text" pInputText placeholder="Search" />
          </p-iconField>
        </div>
        <div class="d-flex gap-2">
          <div class="p-date d-flex">
            <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" />
          </div>
        </div>
      </div>
      <div class="alertInfo text-muted">
        The system automatically clears the logs at 00:00 AM on the 1st of each month.
      </div>
      <p-table [value]="logs">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%;">Log Id</th>
            <th style="width: 12%">Feature Name</th>
            <th style="width: 10%;">Log Type</th>
            <th style="width: 18%;">Timestamp</th>
            <th style="width: 30%;">Summary</th>
            <th style="width: 15%; min-width: 175px">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-logs let-expanded="expanded">
          <tr>
            <td>{{logs.logId}}</td>
            <td>{{logs.featureName}}</td>
            <td>{{logs.logType}}</td>
            <td>{{logs.timeStamp | date:'dd MMM yyyy, hh:mm a' }}</td>
            <td>{{logs.summary}}</td>
            <td>
              <div class="d-flex gap-2 align-items-center">
                <button class="btn btn-link p-0 signUpBtn">More Details</button>
                <span class="pi pi-trash actionIconBtn"></span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="cardHolder" *ngIf="activeItem!.label == 'Log Triggers'">
      <p-table [value]="logTriggers">
        <ng-template pTemplate="header">
            <tr>
                <th>Trigger Id</th>
                <th>Trigger Feature</th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-staff>
            <tr>
                <td>{{ staff.staffId }}</td>
                <td>{{ staff.firstName }} {{ staff.lastName }}</td>
                <td>{{ staff.email }}</td>
                <td>{{ staff.mobile }}</td>
                <td>{{ staff.role }}</td>
                <td>
                    <div class="d-flex gap-2">
                        <span class="pi pi-pen-to-square actionIconBtn"></span>
                        <span class="pi pi-trash actionIconBtn"></span>
                    </div>
                </td> 
            </tr>
        </ng-template>
    </p-table>
    </div>
  </div>
</div>