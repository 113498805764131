<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Locations'"></app-title-bar>
    </div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Location" icon="pi pi-plus" (onClick)="toggleAddLocationModal()" />
            </span>
        </div>
        <p-table [value]="locations" [tableStyle]="{ 'min-width': '50rem' }" [loading]="isDataLoading"
            [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)"
            dataKey="locationId">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th>Location Id</th>
                    <th>Name</th>
                    <th>Created At</th>
                    <th>Last Updated At</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-location let-expanded="expanded">
                <tr>
                    <td>
                        <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                            [pRowToggler]="location" class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                    </td>
                    <td>{{ location.locationId }}</td>
                    <td>{{ location.locationName }}</td>
                    <td>{{ location.createdAt | date:'dd MMM yyyy, hh:mm a' }}</td>
                    <td>{{ location.lastUpdatedAt ? (location.lastUpdatedAt | date:'dd MMM yyyy, hh:mm a') : '-' }}</td>
                    <td class="d-flex gap-3 align-items-center">
                        <span class="statusPill" [ngClass]="{
                        'activePill': location.status === 'active',
                        'pendingPill': location.status === 'pending',
                        'rejectedPill': location.status === 'rejected',
                        'disabledPill': location.status === 'disabled'
                        }">{{ location.status }}</span>
                        <span *ngIf="location.status === 'rejected'" class="pi pi-info-circle actionIconBtn" (click)="rejectionCommentsOP.toggle($event)"></span>
                        <p-overlayPanel #rejectionCommentsOP>
                            <ng-template pTemplate>
                                <p>{{ location.rejectionComments }}</p>
                            </ng-template>
                        </p-overlayPanel>
                    </td>
                    <td *ngIf="location.status == 'active'">
                        <div class="d-flex gap-2">
                            <span class="pi pi-plus actionIconBtn" (click)="toggleAddLocalityModal(location)"></span>
                            <span class="pi pi-pen-to-square actionIconBtn"
                                (click)="toggleAddLocationModal(location)"></span>
                            <span class="pi pi-pause-circle actionIconBtn" (click)="toggleUpdateStatusConfirmation(location)" ></span>
                            <!-- <span class="pi pi-pause-circle" (click)="updateLocationStatus(location.id, 'disabled')" ></span> -->
                            <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteLocationConfirmation(location)"></span>
                        </div>
                    </td>
                    <td *ngIf="location.status == 'pending'">
                        <div class="d-flex gap-2">
                            <span class="pi pi-check-square actionIconBtn" (click)="toggleUpdateStatusConfirmation(location)"></span>
                            <!-- <span class="pi pi-check-square actionIconBtn" (click)="updateLocationStatus(location.id, 'active')"></span> -->
                            <span class="pi pi-times-circle actionIconBtn" (click)="toggleRejectLocation(location)"></span>
                            <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteLocationConfirmation(location)"></span>
                        </div>
                    </td>
                    <td *ngIf="location.status == 'rejected'">
                        <div class="d-flex gap-2">
                            <span class="pi pi-undo actionIconBtn" (click)="toggleAddLocationModal(location)"></span>
                            <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteLocationConfirmation(location)"></span>
                        </div>
                    </td>
                    <td *ngIf="location.status == 'disabled'">
                        <div class="d-flex gap-2">
                            <span class="pi pi-play actionIconBtn" (click)="toggleUpdateStatusConfirmation(location)"></span>
                            <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteLocationConfirmation(location)"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-location>
                <tr>
                    <td colspan="7">
                        <div class="p-3">
                            <p-table [value]="location.localities" [tableStyle]="{ 'min-width': '50rem' }">
                                <ng-template pTemplate="header">
                <tr>
                    <th>Locality Id</th>
                    <th>Locality Name</th>
                    <th>Created At</th>
                    <th>Last Updated At</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-locality>
                <tr>
                    <td>{{ locality.localityId }}</td>
                    <td>{{ locality.localityName }}</td>
                    <td>{{ locality.createdAt | date:'dd MMM yyyy, hh:mm a' }}</td>
                    <td>{{ locality.lastUpdatedAt | date:'dd MMM yyyy, hh:mm a' }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-pen-to-square actionIconBtn"
                                (click)="toggleAddLocalityModal(location, locality.localityId)"></span>
                            <span class="pi pi-trash actionIconBtn"
                                (click)="deleteLocality(location.id, locality.localityId)"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">
                        <div class="d-flex justify-content-center">
                            No localities found for this location.
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">
                <div class="d-flex flex-column justify-content-center align-items-center emptyMsgDiv gap-3">
                    <span class="pi pi-map-marker locationIcon"></span>
                    <span class="emptyMsg">No locations found</span>
                </div>
            </td>
        </tr>
    </ng-template>
    </p-table>
</div>
</div>

<p-dialog [header]="isUpdateLocation ? 'Update Location' : 'Add Location'" class="signUpModal" [modal]="true"
    [(visible)]="showAddLocationModal" [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <form [formGroup]="addLocationForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <label for="locationName">Location Name</label>
                <input pInputText id="locationName" formControlName="locationName" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="locationId">Location Id</label>
                <input pInputText id="locationId" formControlName="locationId" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button [label]="isUpdateLocation ? selectedLocation.status == 'rejected' ? 'Resubmit' : 'Update' : 'Add'" [disabled]="addLocationForm.invalid" (onClick)="registerNewLocation()" />
    </div>
</p-dialog>

<!-- <p-dialog [header]="'Locality'" class="signUpModal" [modal]="true" [(visible)]="showAddLocalityModal" [draggable]="false" -->
<p-dialog [header]="isUpdateLocality ? 'Update Locality' : 'Add Locality to ' + selectedLocation?.locationName"
    class="signUpModal" [modal]="true" [(visible)]="showAddLocalityModal" [draggable]="false" [closable]="true"
    [style]="{ width: '30vw' }">
    <form [formGroup]="addLocalityForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <label for="localityName">Locality Name</label>
                <input pInputText id="localityName" formControlName="localityName" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="localityId">Locality Id</label>
                <input pInputText id="localityId" formControlName="localityId" />
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Continue" [disabled]="addLocalityForm.invalid" (onClick)="registerNewLocality()" />
    </div>
</p-dialog>

<p-dialog header="Comments"
    class="signUpModal" [modal]="true" [(visible)]="showRejectLocationModal" [draggable]="false" [closable]="true"
    [style]="{ width: '30vw' }">
    <form [formGroup]="rejectLocationForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <textarea 
                    rows="5"
                    cols="30" 
                    pInputTextarea
                    [placeholder]="'Input reason for rejecting '+selectedLocation?.locationName"
                    formControlName="rejectionComments">
                </textarea>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Reject" severity="danger" [disabled]="rejectLocationForm.invalid" (onClick)="updateLocationStatus(selectedLocation.id, 'rejected')" />
    </div>
</p-dialog>

<p-dialog header="Confirm"
    class="signUpModal" [modal]="true" [(visible)]="showStatusConfirmationModal" [draggable]="false" [closable]="true"
    [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to {{selectedLocation?.status === 'pending' ? 'approve' : selectedLocation?.status === 'active' ? 'disable' : 'activate'}} <strong>{{selectedLocation?.locationName}}</strong>. Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Confirm" severity="primary" (onClick)="updateLocationStatus(selectedLocation.id, selectedLocation?.status === 'pending' ? 'active' : selectedLocation?.status === 'active' ? 'disabled' : 'active' )" />
    </div>
</p-dialog>

<p-dialog header="Confirm"
    class="signUpModal" [modal]="true" [(visible)]="showDeleteConfirmationModal" [draggable]="false" [closable]="true"
    [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete <strong>{{selectedLocation?.locationName}}</strong>. Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Confirm" severity="danger" (onClick)="deleteLocation(selectedLocation.id)" />
    </div>
</p-dialog>