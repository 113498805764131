import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss'
})
export class ApplicationsComponent {

  activeAction: string | null = null;
  openApplications: any;
  applicationOwners: any[] = [];
  expandedRows: any = {};

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
      if (params['action'] == 'all') {
        this.getApplications()
      } else if (params['action'] == 'open') {
        // Handle 'all' param
      }
    });
  }

  getApplications(action?: string) {
    this.openApplications = []
    this.applicationOwners = []
    this.applicationService.getOpenApplicationOwners().subscribe((res: any) => {
      this.openApplications = res.data().openApplications;
      this.openApplications.forEach((docId: any) => {
        this.applicationService.getOpenApplicationDetails(docId).then((res: any) => {
          this.applicationOwners.push(res)
          console.warn(this.applicationOwners);
          
        })
      });
    })
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }
}
