export const environment = {
    production: false,
    environmentMode: 'DEV',
    firebaseConfig: {
        apiKey: "AIzaSyASxg8dvQJTAunRDMOQHCFOlkWehgTGKlY",
        authDomain: "aahaar-qa.firebaseapp.com",
        projectId: "aahaar-qa",
        storageBucket: "aahaar-qa.appspot.com",
        messagingSenderId: "582989743762",
        appId: "1:582989743762:web:1b1f15712c2acfb199c84b",
        measurementId: "G-39WN1QCY9H"
    },
    showEnvironment: true,
}