<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Orders'"></app-title-bar>
    </div>
    <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search"  />
            </p-iconField> 
        </div>
        <div class="d-flex gap-2">
            <div class="p-field ">
                <p-dropdown id="location" [options]="locations" [(ngModel)]="selectedLocation" placeholder="Location"></p-dropdown>
            </div>  
            <div class="p-date d-flex">
                <p-calendar 
                [(ngModel)]="selectedDate" 
                [iconDisplay]="'input'" 
                [showIcon]="true" 
                inputId="icondisplay" />
            </div>
        </div>
    </div>
    <div class="cardSection">
        In Progress Orders<br>
        <span class="d-flex justify-content-center">1</span>
    </div>
    <div class="bodySection">
        <p-table [value]="outlets" dataKey="restaurantId" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>  
                    <th style="width: 3rem"></th>
                    <th>Restaurant ID</th>
                    <th>Restaurant Info</th>
                    <th>Email Address</th>
                    <th>Active Orders</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-outlets let-expanded="expanded">
                <tr>
                    <td>
                        <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                            [pRowToggler]="outlets" class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                    </td>
                    <td>{{outlets.restaurantId}}</td>
                    <td>
                        <div class="restaurantInfo d-flex gap-1">
                            <div class="imageContainer d-block">
                            <img src="../../../assets/fruitify.png" height="30px" width="30px">
                            </div>
                            <div class="infoField d-flex flex-column">
                                <span class="nameSection">{{outlets.restaurantName}}</span>
                                <span class="numberSection">{{outlets.restaurantNumber}}</span>
                            </div>
                        </div>
                    </td>
                    <td>{{outlets.restaurantEmail}}</td>
                    <td>{{outlets.activeOrders}}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-outlets>
                <tr>
                    <td colspan="8">
                        <p-table [value]="orders">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Order ID</th>
                                    <th>Order Time</th>
                                    <th>Customer Info</th>
                                    <th>Total Amount</th>
                                    <th>Delivery Executive</th>
                                    <th>Payment Status</th>
                                    <th>Items Ordered</th>
                                    <th>Actions</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-orders>
                                <tr>
                                    <td>{{orders.orderId}}</td>
                                    <td>
                                        <div class="timeField d-flex flex-column">
                                            <span class="dateSection">{{orders. orderDate}} , {{orders.orderTime}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="restaurantInfo d-flex gap-1">
                                            <div class="infoField d-flex flex-column">
                                                <span class="nameSection">{{orders.customerName}}</span>
                                                <span class="numberSection">{{orders.customerNumber}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>₹ {{orders.totalAmount}}</td>
                                    <td>
                                        <div class="restaurantInfo d-flex gap-1">
                                            <div class="imageContainer d-block">
                                            <img src="../../../assets/deliveryExe.jpg" height="30px" width="30px">
                                            </div>
                                            <div class="infoField d-flex flex-column">
                                                <span class="nameSection">{{orders.deliveryExeName}}</span>
                                                <span class="numberSection">{{orders.deliveryExeId}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="statusSection d-flex justify-content-center">
                                            {{orders.paymentStatus}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="orderfield d-flex justify-content-around">
                                        <div class="orderInfo">{{orders.itemsOrdered}}</div>
                                        <button>View</button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <span class="pi pi-check-square actionIconBtn"></span>
                                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                                            <span class="pi pi-trash actionIconBtn"></span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>