import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationsService } from '../../services/locations.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';
import { GlobalDataService } from '../../services/global-data.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss'
})
export class LocationsComponent {

  locations: any[] = [];
  showAddLocationModal: boolean = false;
  showAddLocalityModal: boolean = false;
  showRejectLocationModal: boolean = false;
  showStatusConfirmationModal: boolean = false;
  showDeleteConfirmationModal: boolean = false;
  isDataLoading: boolean = false;
  isUpdateLocation: boolean = false;
  isUpdateLocality: boolean = false;
  showSpinner: boolean = false;
  addLocationForm: FormGroup;
  addLocalityForm: FormGroup;
  rejectLocationForm: FormGroup;
  user: any;
  userData: any;
  expandedRows: any = {};
  selectedLocation: any;
  selectedLocalityId: any;
  activeAction: any;

  constructor(private fb: FormBuilder, private locationService: LocationsService,
    private toastService: ToastService, private globalDataService: GlobalDataService, private authService: AuthService,
    private activatedRoute: ActivatedRoute) {
    this.addLocationForm = this.fb.group({
      locationId: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
      locationName: ['', Validators.required],
    });
    this.addLocalityForm = this.fb.group({
      localityId: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
      localityName: ['', Validators.required],
    });
    this.rejectLocationForm = this.fb.group({
      rejectionComments: ['', [Validators.required]],
    });
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userData = this.globalDataService.getUserData();
        if (!this.userData) {
          this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
            this.userData = res.data();
            this.globalDataService.setUserData(this.userData);
          })
        }
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
    // this.getLocations(params['action']);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
        this.getLocations(params['action'])
    });
  }


  getLocations(status: any) { // Add optional 'status' parameter
    this.locations = [];
    this.isDataLoading = true;
  
    this.locationService.getLocationData().subscribe((res: any[]) => {
      res.forEach((location) => {
        if (status === 'all' || location.status.toLowerCase() === status.toLowerCase()) { // Filter based on status
          this.locations.push(location);
        }
      });
  
      // Sort after filtering
      this.locations.sort((a, b) => parseInt(a.locationId) - parseInt(b.locationId));
  
      this.isDataLoading = false;
    }, err => {
      console.warn(err);
      this.isDataLoading = false;
    });
  }

  toggleAddLocationModal(location?: string) {
    this.showAddLocationModal = !this.showAddLocationModal;
    if (!this.showAddLocationModal) {
      this.addLocationForm.reset()
      this.isUpdateLocation = false;
      this.selectedLocation = null;
    } else {
      if (location) {
        this.isUpdateLocation = true;
        this.selectedLocation = location;
        this.showSpinner = true;
        this.locationService.getActiveLocation(this.selectedLocation.id).subscribe((res: any) => {
          this.addLocationForm.patchValue({
            locationName: res.data().locationName,
            locationId: res.data().locationId
          });
          this.addLocationForm.controls['locationId'].disable();
          this.showSpinner = false;
        })
      } else {
        this.isUpdateLocation = false;
        this.selectedLocation = null;
      }
    }
  }

  toggleAddLocalityModal(location?: any, localityId?: string) {
    this.showAddLocalityModal = !this.showAddLocalityModal;
    if (this.showAddLocalityModal) {
      this.showSpinner = true;
      this.selectedLocation = location;
      if(localityId) {
        this.isUpdateLocality = true;
        this.selectedLocalityId = localityId;
        const matchingLocality = this.selectedLocation.localities.find((locality: any) => locality.localityId === localityId);
        this.addLocalityForm.patchValue({
          localityName: matchingLocality.localityName,
          localityId: matchingLocality.localityId
        });
        this.addLocalityForm.controls['localityId'].disable();
      } else {
        this.isUpdateLocality = false;
        this.selectedLocalityId = null;
      }
      this.showSpinner = false;
    } else {
      this.selectedLocation = null;
      this.isUpdateLocality = false;
      this.addLocalityForm.reset();
    }
  }

  registerNewLocation() {
    if (this.isUpdateLocation) {
      var updateObj = {
        status: 'pending',
        locationId: this.selectedLocation.locationId,
        locationName: this.addLocationForm.value.locationName,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
      };
      this.showSpinner = true;
      this.locationService.updateLocation(this.selectedLocation.id, updateObj)
        .then((result) => {
          if (result === 4) {
            this.toastService.showSuccess('Location updated successfully');
            this.showSpinner = false;
            this.toggleAddLocationModal();
            this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
          } else if (result === 2) {
            this.showSpinner = false;
            this.toastService.showError('Location Name already exists.');
          }
        })
        .catch(error => {
          this.toastService.showError('Error updating location');
          this.showSpinner = false;
        });
    } else {      
      var obj = {
        locationId: this.addLocationForm.value.locationId,
        locationName: this.addLocationForm.value.locationName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
        status: 'pending',
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
      };
      this.showSpinner = true;
      this.locationService.addNewLocation(obj).then((res) => {
        if (res === 1) { // Check for null response
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Id already exists.`);
          this.showSpinner = false;
        } else if (res === 2) {
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Name already exists.`);
          this.showSpinner = false;
        } else if (res === 3) {
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Id and Location Name already exists.`);
          this.showSpinner = false;
        } else {
          this.toastService.showSuccess(`${this.addLocationForm.value.locationName}, Location added successfully`);
          this.toggleAddLocationModal();
          this.showSpinner = false;
          this.getLocations(this.activeAction.toLowerCase());
        }
      }, (err: any) => {
        this.showSpinner = false;
        this.toastService.showError(`${this.addLocationForm.value.locationName}, error while adding Location`)
      })
    }
  }

  registerNewLocality() {
    if (this.isUpdateLocality) {
      var updateObj = {
        localityId: this.addLocalityForm.get('localityId')?.getRawValue(),
        localityName: this.addLocalityForm.value.localityName,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
      };
      this.showSpinner = true;
      this.locationService.updateLocality(this.selectedLocation.id, this.addLocalityForm.get('localityId')?.getRawValue(), updateObj)
        .then((result: any) => {
            this.toastService.showSuccess('Locality updated successfully');
            this.toggleAddLocalityModal()
            this.getLocations(this.activeAction.toLowerCase());
            this.showSpinner = false;
        })
        .catch(error => {
          console.error('Error updating locality:', error);
          this.toastService.showError('Error updating locality');
        });
    } else {
      var obj = {
        localityId: this.addLocalityForm.value.localityId,
        localityName: this.addLocalityForm.value.localityName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName
      };
      if (this.selectedLocation.localities) {
        const existingLocality = this.selectedLocation.localities.find(
          (locality: any) =>
            locality.localityId === this.addLocalityForm.value.localityId ||
            locality.localityName === this.addLocalityForm.value.localityName
        );

        if (existingLocality) {
          this.toastService.showError('Locality ID or Locality Name already exists within this location.')
        } else {
          this.showSpinner = true;
          this.locationService.addNewLocality(this.selectedLocation.id, [obj]).then((res) => {
            this.toastService.showSuccess("Locality addedd successfully")
            this.toggleAddLocalityModal();
            this.getLocations(this.activeAction.toLowerCase());
            this.showSpinner = false;
          }, err => {
            this.showSpinner = false;
          });
        }
      } else {
        var arr = [];
        arr.push(obj)
        this.showSpinner = true;
        this.locationService.addNewLocality(this.selectedLocation.id, arr).then((res) => {
          this.toastService.showSuccess("Locality addedd successfully")
          this.toggleAddLocalityModal();
          this.getLocations(this.activeAction.toLowerCase());
          this.showSpinner = false;
        }, err => {
          this.showSpinner = false;
        })
      }
    }
  }

  deleteLocation(docId: any) {
    this.showSpinner = true;
    this.locationService.deleteLocation(docId).then((res: any) => {
      this.toastService.showSuccess('Location deleted successfully');
      this.getLocations(this.activeAction.toLowerCase());
      this.showSpinner = false;
      this.showDeleteConfirmationModal = false;
    })
  }

  deleteLocality(locationDocId: any, localityId: string) {
    const locationToDelete = this.locations.find(location => location.id === locationDocId);

    if (locationToDelete) {
      const localityIndex = locationToDelete.localities.findIndex((locality: any) => locality.localityId === localityId);

      if (localityIndex > -1) {
        locationToDelete.localities.splice(localityIndex, 1); // Remove the locality from the array
        console.log("Removed locality:", localityId, "from location:", locationDocId);

        this.locationService.deleteLocality(locationDocId, { localities: locationToDelete.localities })
          .then(() => {
            this.toastService.showSuccess('Locality deleted successfully');
            this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
          })
          .catch((error: any) => {
            console.error('Error deleting locality:', error);
            this.toastService.showError('Error deleting locality');
          });

      } else {
        console.error("Locality not found with ID:", localityId, "within location:", locationDocId);
      }
    } else {
      console.error("Location not found with ID:", locationDocId);
    }
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  updateLocationStatus(locationId: string, locationStatus: string) {
    this.showSpinner = true;
    let updateData: any = { status: locationStatus };

    if (locationStatus === 'rejected') {
      updateData['rejectionComments'] = this.rejectLocationForm.value.rejectionComments; 
    }
    this.locationService.updateLocationStatus(locationId, updateData) // Or 'inactive', etc.
    .then((result) => {
      if (result === 4) {
        this.toastService.showSuccess('Location status updated successfully');
        this.showSpinner = false;
        this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
        this.showStatusConfirmationModal = false;
        this.showRejectLocationModal = false;
      } else {
        // Handle other potential error codes if needed
        this.showSpinner = false;
        this.showRejectLocationModal = false;
        this.showStatusConfirmationModal = false;
        this.toastService.showError('Error updating location status');
      }
    })
    .catch(error => {
      this.showSpinner = false;
      this.showStatusConfirmationModal = false;
      console.error('Error updating location status:', error);
      this.toastService.showError('Error updating location status');
    });
  }

  toggleRejectLocation(location: any) {
    this.showRejectLocationModal = !this.showRejectLocationModal;
    if(this.showRejectLocationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }
  
  toggleUpdateStatusConfirmation(location: any) {
    this.showStatusConfirmationModal = !this.showStatusConfirmationModal;
    if(this.showStatusConfirmationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleDeleteLocationConfirmation(location: any) {
    this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
    if(this.showDeleteConfirmationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }
}
