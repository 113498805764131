<div class="titleSection d-flex justify-content-center">
    <div class="d-flex align-items-baseline brandLogo" style="gap: 3px;" (click)="navigateToHome()">
        <span class="aahaarTitleOblong">AA</span>
        <span class="aahaarTitle">haar</span>
    </div>
</div>
<div class="navContainer">
    <div class="navItem" *ngFor="let navItem of navItems; let i = index">
        <div *ngIf="!navItem.isExpandable" class="nonExpandableNavItem">
            <div class="d-flex" (click)="updateRoute(navItem.name)">
                <span [class]="navItem.icon + ' navItemIcon'"></span>
                {{ navItem.name }}
            </div>
        </div>
        <div *ngIf="navItem.isExpandable">
            <p-accordion class="navAccordion" (onOpen)="onTabOpen(navItem.name)" (onClose)="onTabClose(navItem.name)">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="navItemHeader d-flex w-100 justify-content-between align-items-center">
                            <div class="d-flex">
                                <span [class]="navItem.icon + ' navItemIcon'"></span>
                                {{ navItem.name }}
                            </div>
                            <i class="pi pi-chevron-down expandIcon"
                                [ngClass]="{'rotate': activeIndices[navItem.name]}"></i>
                        </div>
                    </ng-template>
                    <div class="expandedNavBody d-flex gap-2 flex-wrap">
                        <div class="subNavItem" *ngFor="let subNavItem of navItem.subNavItems"
                            (click)="updateRoute(navItem.name, subNavItem)">
                            {{subNavItem}}
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<!-- <div class="navContainer">
    <div class="navItem" *ngFor="let nav of navItems"> 
      <div class="sidebar-nav d-flex align-items-center"> 
        <i class="{{ nav.icon }} navItemIcon"></i> 
        <span class="nav-name ml-2">{{ nav.name }}</span> 
      </div>
    </div>
  </div> -->
<div class="profileSection" (click)="profileOverlayPanel.toggle($event)">
    <div class="d-flex align-items-center justify-content-between w-100 gap-2">
        <div class="profileIcon">
            <span class="profileInitials">{{ userData?.firstName?.charAt(0) }}{{ userData?.lastName?.charAt(0) }}</span>
        </div>
        <div class="ml-2 d-flex flex-column profileName">
            <span class="userInfo">{{ userData?.firstName }} {{ userData?.lastName }}</span>
            <span class="text-muted userEmail">{{ userData?.email }}</span>
        </div>
    </div>
    <p-overlayPanel #profileOverlayPanel [style]="{width: '200px'}" appendTo="body" (onShow)="alignOverlayPanel($event)"> 
        <ng-template pTemplate="content">
          <div class="profileOptions">
            <div class="optionItem d-flex justify-content-between align-items-center" (click)="logout()">
                <span>Sign Out</span>
                <span class="pi pi-sign-out"></span>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
</div>