<div class="mainContainer h-100 w-100">
    <div>

        <app-title-bar [activeNav]="'Customer Complaints'"></app-title-bar>
    </div>
    <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search" />
            </p-iconField>
        </div>
        <div class="d-flex gap-2">
            <div class="p-field ">
                <p-dropdown id="location" [options]="locations" [(ngModel)]="selectedLocation"
                    placeholder="Location"></p-dropdown>
            </div>
            <div class="p-date d-flex">
                <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                    inputId="icondisplay" />
            </div>
        </div>
    </div>
    <div class="cardholder">
        <div class="cards">Total Complaints<br>
            <span>3</span>
        </div>
        <div class="cards">New Complaints<br>
            <span>4</span>
        </div>
        <div class="cards">Pending Complaints<br>
            <span>2</span>
        </div>
        <div class="cards">Resolved Complaints<br>
            <span>1</span>
        </div>
    </div>
    <div class="tableSection">
        <p-table [value]="complaints">
            <ng-template pTemplate="header">
                <tr>
                    <th>S.No</th>
                    <th>Order ID</th>
                    <th>Customer Name</th>
                    <th>Complaint Date</th>
                    <th>Rating</th>
                    <th>Reasons</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-complaints>
                <tr>
                    <td>{{complaints.sNo}}</td>
                    <td>{{complaints.orderID}}</td>
                    <td>
                        <div class="name d-flex flex-column gap-1">
                            {{complaints.customerName}}<span class="numberSection">{{complaints.mobileNumber}}</span>
                        </div>
                    </td>
                    <td>{{complaints.complaintDate}}</td>
                    <td>
                        <div class="stars">
                            <p-rating [(ngModel)]="value" [cancel]="false" />
                        </div>
                    </td>
                    <td>{{complaints.reasons}}</td>
                    <td>
                        <!-- Dynamically render the status chip based on the current status -->
                        <div class="statusChip" [ngClass]="getStatusClass(complaints.status)">
                            {{ complaints.status }}
                        </div>
                    </td>
                    <td>
                        <div class="chipsContainer">
                            <!-- Actions Chips Column -->
                            <!--<div *ngFor="let action of complaints.Actions.split(', ')">
                                <div class="actionChip" [ngClass]="getActionClass(action)">
                                    {{ action }}
                                </div>
                            </div>-->
                            <div class="d-flex gap-3">
                                <span class=" pi pi-eye actionIconBtn" style="font-size: 1rem"></span>
                                <span class=" pi pi-comments actionIconBtn"></span>
                                <span class=" pi pi-verified actionIconBtn"></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>