import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './shared/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { OutletsComponent } from './components/outlets/outlets.component';
import { OrdersComponent } from './components/orders/orders.component';
import { StaffComponent } from './components/staff/staff.component';
import { LocationsComponent } from './components/locations/locations.component';
import { LogsComponent } from './components/logs/logs.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard', component: DashboardComponent
      }, 
      {
        path: 'applications', component: ApplicationsComponent
      }, 
      {
        path: 'complaints', component: ComplaintsComponent
      },
      {
        path: 'outlets', component: OutletsComponent
      },
      {
        path: 'orders', component: OrdersComponent
      },
      {
        path: 'staff', component: StaffComponent
      },
      {
        path: 'locations', component: LocationsComponent
      },
      {
        path: 'logs' , component: LogsComponent
      }
    ]
  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
