import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrl: './complaints.component.scss'
})
export class ComplaintsComponent implements OnInit {
  complaints: any[] = [];
  activeAction: string | null = null;
  selectedDate: Date | null = null;
  locations: any[] = [];
  selectedLocation: any = null;
  value: string = '';
  status: string = 'New'; // Set the current status here




  getStatusClass(status: string): string {
    switch (status) {
      case 'New':
        return 'statusNew';
      case 'Pending':
        return 'statusPending';
      default:
        return '';
    }
  }

  /*getActionClass(action: string): string {
    switch (action.trim()) {
      case 'View':
        return 'action-view';
      case 'Respond':
        return 'action-respond';
      default:
        return '';
    }
  // }*/


  getRatingColor(value: number): string {
    if (value === 1 || value === 2) {
      return 'redRating';
    } else if (value === 3) {
      return 'orangeRating';
    } else if (value === 4) {
      return 'yellowRating';
    } else if (value === 5) {
      return 'greenRating';
    }
    return '';
  }


  // Correct the placement of ngOnInit inside the class
  ngOnInit(): void {
    this.complaints = [
      {

        sNo: '1',
        orderID: '098765',
        customerName: 'Kiran',
        mobileNumber: '8787626299',
        complaintDate: '1 June 2024',
        rating: '1',
        reasons: 'Food was good',
        status: 'New',
        actions: 'View',
      },
      {

        sNo: '2',
        orderID: '432109',
        customerName: 'Stella',
        mobileNumber: '8978629245',
        complaintDate: '1 June 2024',
        rating: '2',
        reasons: 'Fast delivery',
        status: 'Pending',
      },
      /*{

        SNo: '3',
        OrderID: '345678',
        CustomerName: 'John',
        MobileNumber: '7854623262',
        ComplaintDate: '1 June 2024',
        Rating: '5',
        Reasons: 'Fast delivery as i expected',
        Status: 'Resolved',
        Actions: 'View, Respond',
      },*/
    ];
  }
}
