import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})

export class OrdersComponent {
  activeAction: string | null = null;
  orders: any[] =[];
  outlets: any[] =[];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  locations: any[] = [];
  selectedLocation: any = null;

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
    this.outlets = [
      {
        restaurantId: '1234565',
        restaurantName: 'Fruitify',
        restaurantNumber: '8919619792',
        restaurantEmail: 'contactfruitify@gmail.com',
        activeOrders: '2 Orders',
      },
    ];
    this.orders = [
      {
        orderId: '234556',
        orderTime: '12:00 PM',
        orderDate: '20 june 2024',
        customerName: 'uma',
        customerNumber: '8919619792',
        totalAmount: '300/-',
        deliveryExeName: 'Ramu',
        deliveryExeId: '123456',
        paymentStatus: 'Paid',
        itemsOrdered: '2 items'
      },
    ];

     this.locations = [
      
     ];
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }

}
