import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../services/global-data.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {

  activeIndices: { [key: string]: boolean } = {}; // Store active indices using navItem.name as key
  navItems = [
    {
      name: 'Dashboard',
      icon: 'pi pi-home',
      route: '/dashboard',
      isExpandable: false
    },
    {
      name: 'Staff',
      icon: 'pi pi-users',
      route: '/dashboard',
      isExpandable: true,
      subNavItems: [
        'All',
        'Super Admin',
        'Developer'
      ]
    },
    {
      name: 'Applications',
      icon: 'pi pi-folder-open',
      route: '/dashboard',
      isExpandable: true,
      subNavItems: [
        'All',
        'Open',
        'Rejected'
      ]
    },
    {
      name: 'Outlets',
      icon: 'pi pi-shop',
      route: '/dashboard',
      isExpandable: true,
      subNavItems: [
        'Online',
        'Offline',
        'Disabled'
      ]
    },
    {
      name: 'Orders',
      icon: 'pi pi-box',
      route: '/orders',
      isExpandable: true,
      subNavItems: [
        'In Progress',
        'Active',
        'Pending',
        'Delivered'
      ]
    },
    {
      name: 'Locations',
      icon: 'pi pi-map-marker',
      route: '/locations',
      isExpandable: true,
      subNavItems: [
        'All',
        'Active',
        'Disabled',
        'Pending',
        'Rejected'
      ]
    },
    {
      name: 'complaints',
      icon: 'pi pi-exclamation-circle',
      route: 'home/complaints',
      isExpandable: true,
      subNavItems: [
        'Active',
        'Resolved'
      ]
    },
    {
      name: 'Logs',
      icon: 'pi pi-history',
      route: '/logs',
      isExpandable: false
    },
    // { name: 'Dashboard', icon: 'pi pi-chart-bar', route: '/dashboard' },
    // { name: 'Settings', icon: 'pi pi-cog', route: '/settings' },
  ];
  @Input() userData: any;
  @ViewChild('profileOverlayPanel') profileOverlayPanel!: OverlayPanel;
  activeIndex: number | null = null;
  activeIndicesArr: number[] = []; 

  constructor(private authService: AuthService, private router: Router, private globalDataService: GlobalDataService, private firebaseAuth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.activeIndex = this.checkAccordion(); 
  }

  onTabOpen(navItemName: string) {
    this.activeIndices[navItemName] = true;
  }

  onTabClose(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  onClick(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  navigateToHome() {

  }

  updateRoute(mainRoute: string, subRoute?: string) {
    this.globalDataService.setUserData(this.userData);
    if(subRoute) {
      this.router.navigate(['home', mainRoute.toLowerCase()], {
        queryParams: { action: subRoute?.toLowerCase() }
      });
    } else {
      this.router.navigate(['home', mainRoute.toLowerCase()])
    }
  }

  logout() {
    this.profileOverlayPanel.hide();
    this.firebaseAuth.signOut();
    this.router.navigateByUrl('/login')
  }

  alignOverlayPanel(event: any) {
    setTimeout(() => {
      var ele = this.profileOverlayPanel.container as HTMLElement;
      var leftValue = parseFloat(ele.style.left);
      var topValue = parseFloat(ele.style.top);
      var profileEle = document.getElementsByClassName('profileSection')[0] as HTMLElement
      leftValue = leftValue + profileEle.offsetWidth + profileEle.offsetLeft * 2
      topValue = topValue + profileEle.offsetHeight
      ele.style.left = `${leftValue}px`
      ele.style.top = `${topValue}px`
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  checkAccordion() {
    const currentUrl = window.location.href;
    let returnVal = 0; 
  
    this.navItems.forEach((item, index) => {
      const pathToCheck = `/home/${item.name.toLowerCase()}`; // Construct the exact path to match
      if (currentUrl.includes(pathToCheck)) {
        returnVal = index;
      }
    });
  
    return returnVal;
  }

  isNavItemActive(navItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes('home/'+navItem.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  isSubNavItemActive(navItem: string, subNavItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes(navItem.toLowerCase()) && currentUrl.includes(subNavItem.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  preventExpansion(event: any, isExpandable: boolean, navItemName: string) {
    if (!isExpandable) {
      event.stopPropagation();
      this.updateRoute(navItemName);
    }
  }
}
