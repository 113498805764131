<div class="mainContainer h-100 w-100">
    <div class="titleSection">
        <span>
            {{activeAction}} Applications
        </span>
    </div>
    <div class="bodySection">
        <p-table [value]="applicationOwners" dataKey="ownerId" [expandedRowKeys]="expandedRows"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th>Owner ID</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-owner let-expanded="expanded">
                <tr>
                    <td>
                        <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                            [pRowToggler]="owner" class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                    </td>
                    <td>{{ owner.ownerId }}</td>
                    <td>{{ owner.firstName }} {{ owner.lastName }}</td>
                    <td>{{ owner.mobile }}</td>
                    <td>{{ owner.email }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                            <span class="pi pi-send actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-owner>
                <tr>
                    <td colspan="6">
                        <p-table [value]="owner.openOutletApplications" *ngIf="owner.openOutletApplications.length > 0">
                            <ng-template pTemplate="header">
                <tr>
                    <th>Application ID</th>
                    <th>Outlet Name</th>
                    <th>Outlet Location</th>
                    <th>Outlet Category</th>
                    <th>Current Step</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-application>
                <tr>
                    <td>{{ application.applicationId }}</td>
                    <td>{{ application.outletDetails?.outletName ? application.outletDetails?.outletName : '-' }}</td>
                    <td>
                        <ng-container *ngIf="application.outletDetails?.outletLocation">
                            {{ application.outletDetails.outletLocation.outletLatitude }},
                            <br>
                            {{ application.outletDetails.outletLocation.outletLongitude }}
                            <br>
                            <a href="" (click)="openInMaps(application.outletDetails.outletLocation.outletLatitude, application.outletDetails.outletLocation.outletLongitude)" target="_blank">View in Maps</a> 
                        </ng-container>
                        <ng-container *ngIf="!application.outletDetails?.outletLocation">
                            -
                        </ng-container>
                    </td>
                    <td>{{ application.outletDetails?.outletCategory }}</td>
                    <td>{{ application.currentStep }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-check-square actionIconBtn"></span>
                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="owner.openOutletApplications.length === 0">No open applications</div>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
</div>