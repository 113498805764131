<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 vh-100 d-flex justify-content-center align-items-center">
    <div class="subContainer" >
        <div class="leftPanel">
            <div class="d-flex flex-column h-100 gap-4 justify-content-center">
                <div class="headerSection d-flex justify-content-center">
                    {{!showResetForm ? 'Sign In' : 'Reset Password'}}
                </div>
                <form (ngSubmit)="staffLogin()" [formGroup]="userLoginForm" *ngIf="!showResetForm" class="d-flex flex-column gap-3">
                    <div class="mailSection d-flex justify-content-center">
                        <p-iconField iconPosition="right">
                            <p-inputIcon styleClass="pi pi-envelope" />
                            <input type="email" pInputText placeholder="Email" formControlName="email"/>
                        </p-iconField> 
                    </div>
                    <div class="securitySection d-flex justify-content-center">
                        <p-iconField iconPosition="right">
                            <input [type]="isPasswordVisible ? 'text' : 'password'" pInputText placeholder="Password" formControlName="password" />
                            <i [ngClass]="isPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="togglePasswordVisibility()"></i>
                        </p-iconField> 
                    </div>
                </form>
                <form [formGroup]="userResetForm" *ngIf="showResetForm">
                    <div class="mailSection d-flex justify-content-center">
                        <p-iconField iconPosition="right">
                            <p-inputIcon styleClass="pi pi-envelope" />
                            <input type="email" pInputText placeholder="Email" formControlName="email"/>
                        </p-iconField> 
                    </div>
                </form>
                <div *ngIf="!showResetForm" class="createSection d-flex justify-content-center">
                    <p-button label="Forget Password?" [link]="true" (onClick)="toggleReset()"/> 
                </div>
                <div *ngIf="showResetForm" class="createSection d-flex justify-content-center">
                    <p-button label="Back to sign in" [link]="true" (onClick)="toggleReset()"/> 
                </div>
                <div *ngIf="!showResetForm" class="btnSection d-flex justify-content-center">
                    <p-button label="SIGN IN" [rounded]="true" [disabled]="userLoginForm.invalid" (click)="staffLogin()" />
                </div>
                <div *ngIf="showResetForm" class="btnSection d-flex justify-content-center">
                    <p-button label="Reset" [rounded]="true" [disabled]="userResetForm.invalid" (click)="initReset()" />
                </div>
            </div>
        </div>
        <div class="rightPanel">
            <div class="d-flex flex-column gap-4 justify-content-center h-100">
                <div class="greetSection d-flex justify-content-center">
                    Welcome Back!
                </div>
                <div class="infoSection ">
                    <div class="firstInfo d-flex justify-content-center">
                        Enter login credentials 
                    </div>
                    <div class="secondInfo d-flex justify-content-center">
                        to get into your Staff account
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
