import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaffService } from '../../services/staff.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrl: './staff.component.scss'
})
export class StaffComponent {

  activeAction: string | null = null;
  staffMembers: any[] = [];
  isTableLoading: boolean = false;
  showStaffModal: boolean = false;
  addStaffForm: FormGroup;
  staffRoles = [
    { name: 'Super Admin', value: 'Super Admin' },
    { name: 'Admin', value: 'Admin' },
    { name: 'Developer', value: 'Developer' },
];

  constructor(private activatedRoute: ActivatedRoute, private staffService: StaffService, private fb: FormBuilder, private afAuth: AngularFireAuth,
    private toastService: ToastService
  ) {
    this.addStaffForm = this.fb.group({
      email: ['', Validators.required],
      role: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
        this.getStaff(params['action'])
    });
  }

  getStaff(action: string | null) {
    this.isTableLoading = true;
    this.staffMembers = []; // Clear the array before fetching new data
    this.staffService.getAllStaffMembers().then((res) => {
      res.forEach((staff) => {
        const staffMember: any = staff.data();
        if (action === 'all' || staffMember.role.toLowerCase() === action!.toLowerCase()) {
          this.staffMembers.push(staffMember); 
        }
      });
  
      // Sort after filtering
      this.staffMembers.sort((a, b) => parseInt(a.staffId) - parseInt(b.staffId));
  
      this.isTableLoading = false;
    }, err => {
      console.warn(err);
      this.isTableLoading = false; 
    });
  }

  toggleAddStaffModal() {
    this.showStaffModal = !this.showStaffModal;
  }

  registerNewStaff() {
    if (this.addStaffForm.valid) {
      const email = this.addStaffForm.value.email;
      const password = 'aahaar2024'; // Constant password
      this.staffService.createNewStaffMember(email, password).then((res: any) => {
        console.warn(res);
        var obj = {
          uid: res.uid,
          email: this.addStaffForm.value.email,
          role: this.addStaffForm.value.role.value
        }
        console.warn(obj);
        
        this.staffService.addStaff(res.uid, obj).then((res) => {
          this.toastService.showSuccess("Staff member addedd successfully");
          this.staffMembers = []
          this.getStaff(this.activeAction)
        }, err => {
          console.warn(err);
        })
        this.showStaffModal = false;
      }, err => {
        console.error(err)
      })
    } else {
      this.addStaffForm.markAllAsTouched();
    }
  }
}
